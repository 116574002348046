import React from "react"
import { Helmet } from "react-helmet"
import Layout from "layouts/layout"
import ColumnLayout from "layouts/column-layout"
import CTA from "components/call-to-action"

// Page sections
import KeyDocuments from "../about-helios-underwriting/overview/_key-documents"

class LeadingVoices extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Leading voices | Helios Underwriting</title>
        </Helmet>
        <ColumnLayout aside={<KeyDocuments />}>
          <div className="u-flex u-flex-col u-justify-between u-h-full">
            <div>
              <section className="padding-md">
                <h1 className="h1 mb-20">
                  <div className="mb-40">Leading voices</div>
                  <div className="lead">
                    Michael Wade talks to The Insurer TV.
                  </div>
                </h1>
                <p className="u-color-emperor">
                  Helios Executive Chairman Michael Wade talks to The Insurer TV
                  about the potential of the follow market.
                </p>
              </section>

              <section className="padding-md">
                <script
                  src="https://fast.wistia.com/assets/external/E-v1.js"
                  async
                ></script>
                <div className="o-placeholder o-placeholder--16-9">
                  <iframe
                    title="Michael Wade talks to The Insurer TV."
                    src="//fast.wistia.net/embed/iframe/pn0t34fe1f?&playerColor=6e7896"
                    allowtransparency="true"
                    frameborder="0"
                    scrolling="no"
                    class="wistia_embed"
                    name="wistia_embed"
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                  ></iframe>
                </div>
              </section>
            </div>

            <CTA
              title="Helios Underwriting overview"
              link="/about-helios-underwriting/overview"
            />
          </div>
        </ColumnLayout>
      </Layout>
    )
  }
}

export default LeadingVoices
