import React from "react"
import { Helmet } from "react-helmet"
import Layout from "layouts/layout"
import ColumnLayout from "layouts/column-layout"
import Highlights from "./_highlights"
import ReturnOnCapacity from "./_charts/_helios-return-on-capacity"
import CTA from "components/call-to-action"
import KeyDocuments from "./_key-documents"

export default () => (
  <Layout>
    <Helmet>
      <title>The investment case | Helios Underwriting</title>
    </Helmet>
    <ColumnLayout aside={<KeyDocuments />}>
      <section className="padding-md">
        <h1 className="h1 mb-40">Why invest in Helios?</h1>
        <p className="lead u-color-emperor">
          Helios offers access to insurance and reinsurance exposures, providing
          a growth and income opportunity.
        </p>
      </section>

      <Highlights />

      <ReturnOnCapacity />

      <section className="padding-md mb-40">
        <h2 className="h2">Inheritance tax</h2>
        <p className="u-color-emperor ">
          Helios Underwriting Plc has been advised that its ordinary shares are
          eligible for Business Relief (formerly known as Business Property
          Relief) for Inheritance Tax purposes. The exemption is applicable for
          shareholdings in unquoted trading companies. For these purposes, AIM
          listed shares are considered to be unquoted. This is not intended to
          be tax advice. Individual investors should seek their own personal tax
          advice prior to making decisions to trade in Helios Underwriting Plc’s
          share capital.
        </p>
      </section>

      <CTA title="Financial summary" link="/investors/financial-summary" />
    </ColumnLayout>
  </Layout>
)
