import React from "react"
import Divider from "components/divider"
import Chart from "components/chart"
import { chartDeferredOptions } from "chart-options"

const data = {
  labels: ["2020", "2021", "2022", "2023", "2024"],
  datasets: [
    {
      label: "Value",
      data: [151, 157, 152, 189, 206],
      backgroundColor: "rgba(220, 100, 60, 0.5)",
      borderColor: "#dc643c",
      borderSkipped: false,
      borderWidth: 1,
    },
  ],
}

const options = Object.assign(
  {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: { fontSize: 14 },
          categoryPercentage: 0.6,
          barPercentage: 0.75,
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontSize: 14,
            min: 0,
            max: 250,
            callback: function(label) {
              return label.toFixed(0) + ""
            },
          },
        },
      ],
    },
  },
  chartDeferredOptions
)

export default () => (
  <section className="padding-md">
    <Divider color="terracotta" />
    <h2 className="mt-20 h2">Net Tangible Asset Value per Share</h2>
    <Chart title="(current estimate in pence)" data={data} options={options} />
  </section>
)
