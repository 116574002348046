import React from "react"
import Divider from "components/divider"
import Chart from "components/chart"

const type = "doughnut"

const data = {
  labels: [
    "Accident & Health",
    "Aviation",
    "Casualty FinPro",
    "Casualty Other",
    "Casualty Treaty",
    "Energy",
    "Marine",
    "Property (D&F)",
    "Property Treaty",
    "Specialty Other",
  ],
  datasets: [
    {
      label: "Total holdings by industry",
      data: [6, 4, 16, 18, 1, 5, 9, 18, 14, 8],
      backgroundColor: [
        "#FFBF00",
        "#FF8C12",
        "#E05E2B",
        "#B63400",
        "#8D0000",
        "#E9EEFF",
        "#B8CAFF",
        "#7C90CF",
        "#485C9B",
        "#00144A",
      ],
      borderSkipped: false,
      borderWidth: 0,
    },
  ],
}

const baseOptions = {
  responsive: true,
  maintainAspectRatio: false,
}

export default () => (
  <section className="padding-md">
    <Divider color="terracotta" />
    <h2 className="mt-20 h2">Helios portfolio</h2>
    <Chart
      type={type}
      title="Total holdings by industry (%)"
      lastUpdated="Jan 2025"
      data={data}
      options={baseOptions}
    />
  </section>
)
