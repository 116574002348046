import React from "react"
import { Bar, Doughnut } from "react-chartjs-2"
import "chartjs-plugin-deferred"
import { doughnutOptions, chartDeferredOptions } from "../chart-options"

const getTypeSpecificOptions = (type, baseOptions) => {
  if (type === "doughnut") {
    return { ...baseOptions, ...doughnutOptions, ...chartDeferredOptions }
  }

  return { ...baseOptions, ...chartDeferredOptions }
}

const chartTypes = {
  bar: Bar,
  doughnut: Doughnut,
}

class Chart extends React.Component {
  render() {
    const { data, title, options = {}, type = "bar", lastUpdated } = this.props
    const ChartComponent = chartTypes[type] || Bar

    const finalOptions = getTypeSpecificOptions(type, options)

    return (
      <div className="o-chart">
        <h3 className="o-chart__title">
          {title}
          {lastUpdated && (
            <span className="o-chart__date"> ({lastUpdated})</span>
          )}
        </h3>
        <ChartComponent data={data} options={finalOptions} />
      </div>
    )
  }
}

export default Chart
