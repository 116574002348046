import React from "react"
import { Link } from "gatsby"

import styles from "components/footer.module.scss"

export default () => (
  <div className={styles.footer}>
    <a href="https://www.unpri.org/" rel="noopener noreferrer" target="_blank">
      <img
        className={styles.footer_pri}
        alt="PRI, Principles for Responsible Investment"
        src="/assets/pri-logo.png"
        width="154"
      />
    </a>
    <div className={styles.footer_inner}>
      <div className="u-flex u-justify-start md:u-items-center u-flex-col md:u-flex-row">
        <span className="u-bold">
          &copy; Helios Underwriting Plc {new Date().getFullYear()}
        </span>
        <Link className={styles.link} to="/legal-notices">
          Legal Notices
        </Link>
        <Link className={styles.link} to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link className={styles.link} to="/cookie-policy">
          Cookie Policy
        </Link>
        <Link className={styles.link} to="/modern-slavery-statement">
          Modern Slavery Statement
        </Link>
      </div>
      <div>
        <span>Site by </span>
        <a rel="noopener" href="https://kohde.agency/">
          Kohde
        </a>
      </div>
    </div>
  </div>
)
