import React from "react"
import Divider from "components/divider"

export default () => (
  <section className="o-container mt-20 mb-40">
    <Divider color="terracotta" />
    <h2 className="mt-20 mb-40 h2">Helios portfolio</h2>

    <div className="o-table__title">
      <span className="u-bold">Total holdings by Managing Agent</span> (Jan
      2025)
    </div>

    <div className="o-table__container">
      <table className="o-table o-table--llrr">
        <thead>
          <tr>
            <th width="10%">Syndicate</th>
            <th>Managing agent</th>
            <th width="10%">Capacity £M</th>
            <th width="10%">Helios portfolio % of total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>33</td>
            <td>Hiscox</td>
            <td>15.11</td>
            <td>3.12</td>
          </tr>
          <tr>
            <td>218</td>
            <td>ERS</td>
            <td>19.4</td>
            <td>4.01</td>
          </tr>
          <tr>
            <td>318</td>
            <td>Cicinnati</td>
            <td>1.08</td>
            <td>0.22</td>
          </tr>
          <tr>
            <td>386</td>
            <td>QBE</td>
            <td>2.89</td>
            <td>0.6</td>
          </tr>
          <tr>
            <td>510</td>
            <td>TMK</td>
            <td>15.31</td>
            <td>3.16</td>
          </tr>
          <tr>
            <td>609</td>
            <td>Atrium</td>
            <td>18.79</td>
            <td>3.88</td>
          </tr>
          <tr>
            <td>623</td>
            <td>Beazley</td>
            <td>28.87</td>
            <td>5.96</td>
          </tr>
          <tr>
            <td>727</td>
            <td>Meacock</td>
            <td>2.96</td>
            <td>0.61</td>
          </tr>
          <tr>
            <td>1176</td>
            <td>Chaucer</td>
            <td>2.58</td>
            <td>0.53</td>
          </tr>
          <tr>
            <td>1609</td>
            <td>Mosiac</td>
            <td>20</td>
            <td>4.13</td>
          </tr>
          <tr>
            <td>1729</td>
            <td>Dale</td>
            <td>25.12</td>
            <td>5.19</td>
          </tr>
          <tr>
            <td>1902</td>
            <td>MCI</td>
            <td>12.64</td>
            <td>2.61</td>
          </tr>
          <tr>
            <td>1910</td>
            <td>Ariel Re</td>
            <td>20</td>
            <td>4.13</td>
          </tr>
          <tr>
            <td>1925</td>
            <td>Envelop</td>
            <td>7.5</td>
            <td>1.55</td>
          </tr>
          <tr>
            <td>1955</td>
            <td>Arch</td>
            <td>24.64</td>
            <td>5.09</td>
          </tr>
          <tr>
            <td>1966</td>
            <td>MCI 2</td>
            <td>12.6</td>
            <td>2.6</td>
          </tr>
          <tr>
            <td>1971</td>
            <td>Apollo</td>
            <td>25</td>
            <td>5.16</td>
          </tr>
          <tr>
            <td>1985</td>
            <td>Flux</td>
            <td>12.69</td>
            <td>2.62</td>
          </tr>
          <tr>
            <td>2024</td>
            <td>ADA</td>
            <td>6.75</td>
            <td>1.39</td>
          </tr>
          <tr>
            <td>2121</td>
            <td>Argenta</td>
            <td>5.21</td>
            <td>1.08</td>
          </tr>
          <tr>
            <td>2358</td>
            <td>Nephila</td>
            <td>25</td>
            <td>5.16</td>
          </tr>
          <tr>
            <td>1427</td>
            <td>Agile</td>
            <td>15</td>
            <td>3.1</td>
          </tr>
          <tr>
            <td>2454</td>
            <td>ASR</td>
            <td>7.5</td>
            <td>1.55</td>
          </tr>
          <tr>
            <td>2525</td>
            <td>Secure Lby Solutions</td>
            <td>2.41</td>
            <td>0.5</td>
          </tr>
          <tr>
            <td>2689</td>
            <td>HRP</td>
            <td>14.76</td>
            <td>3.05</td>
          </tr>
          <tr>
            <td>2791</td>
            <td>MAP</td>
            <td>16.17</td>
            <td>3.34</td>
          </tr>
          <tr>
            <td>3123</td>
            <td>Fidelis</td>
            <td>14.06</td>
            <td>2.9</td>
          </tr>
          <tr>
            <td>3939</td>
            <td>Norman Max</td>
            <td>12</td>
            <td>2.48</td>
          </tr>
          <tr>
            <td>4242</td>
            <td>Beat</td>
            <td>16.52</td>
            <td>3.41</td>
          </tr>
          <tr>
            <td>5623</td>
            <td>Beazley</td>
            <td>26.84</td>
            <td>5.55</td>
          </tr>
          <tr>
            <td>5886</td>
            <td>Blenheim</td>
            <td>37.48</td>
            <td>7.74</td>
          </tr>
          <tr>
            <td>6103</td>
            <td>MAP</td>
            <td>4.62</td>
            <td>0.95</td>
          </tr>
          <tr>
            <td>6104</td>
            <td>Hiscox</td>
            <td>12.01</td>
            <td>2.48</td>
          </tr>
          <tr>
            <td>6117</td>
            <td>Ariel Re</td>
            <td>0.57</td>
            <td>0.12</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2">Total Helios portfolio</td>
            <td>484</td>
            <td>100</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
)
