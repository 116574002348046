import React from 'react'
import axios from 'axios'
import { formatDateTime } from 'helpers'
import Statistic from 'components/statistic'
import styles from 'pages/home/home.module.scss'
import Divider from 'components/divider'



class Latest extends React.Component {

  state = {
    error: false,
    announcementsData: null,
  }

  componentDidMount() {
    this.fetchAnnouncementsData();
  }

  fetchAnnouncementsData = () => {
    axios
      .get('https://tools.euroland.com/tools/services/?func=GetCompanyPressReleasesByDateRange&companycode=uk-huw&format=json&startdate=2010-01-01&enddate=2050-01-01&amount=1')
      .then(response => {
        this.setState({
          announcementsData: response.data && response.data[0],
        })
      })
      .catch(error => {
        this.setState({error})
      })
  }

  render() {

    return (
      <div className={`pb-30 ${styles.overlay}`}>
        <div className={`${styles.grid} ${styles.gridLatest}`}>
          <div className={styles.gridItem}>
            <div className="mb-10">
              <h4 className="h4">At a glance</h4>
              <Statistic size="small" text="Net tangible asset value" number="206p" />
              <Statistic size="small" text="Outperformance against Lloyd’s market" number="9%" />
            </div>
          </div>

          <div className={styles.gridItem}>

            <h4 className="h4">Latest announcement</h4>

            {this.state.announcementsData && (
              <div className={styles.announcement}>
                <a href={unescape(this.state.announcementsData.link)} rel="noopener" className={styles.announcementTitle}>{this.state.announcementsData.title}
                </a>
                <div className="c-link c-link--terracotta">
                  <a href={unescape(this.state.announcementsData.link)} rel="noopener">
                    <span className="u-bold">RNS</span>
                    &ensp;
                    <span>{formatDateTime(this.state.announcementsData.date)}</span>
                  </a>
                  <Divider color="terracotta" />
                </div>
              </div>
            )}

          </div>


          <div className={styles.gridItem}>
            {/* <div className="mb-30">
              <h4 className="h4">Fundraising</h4>
              <div className="article-title mb-20">
              Proposed fundraising and acquisition May / June 2019
              </div>
              <p className="mb-20">Shareholder documents can be found here.</p>
              <TextLink color="terracotta" url="/investors/fundraising">
                Rule 9 documents
              </TextLink>
            </div> */}
          </div>

        </div>
      </div>
    )
  }
}

export default Latest