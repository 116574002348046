import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import ColumnLayout from 'layouts/column-layout'
import styles from './sell-your-lloyds-vehicle.module.scss'

// Page sections
import KeyDocuments from '../about-helios-underwriting/overview/_key-documents'

class SellYourLloydsVehicle extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Sell your Lloyd’s vehicle | Helios Underwriting</title>
        </Helmet>
        <ColumnLayout aside={<KeyDocuments />}>

          <div className="u-flex u-flex-col u-justify-between u-h-full">

            <section className="padding-md">
              <h1 className="h1 mb-40">
                Sell your Lloyd’s vehicle
              </h1>

              <div className="u-color-emperor">
                <p className="lead mb-20">
                  Helios is interested in purchasing your Lloyd’s vehicle. Helios has agreed terms to buy over 75 Lloyd’s corporate members in recent years.
                </p>

                <p>
                  If you are considering a sale of your Lloyd’s vehicle, you will first need to obtain a valuation. Helios will obtain this valuation on your behalf at no cost or obligation to you. If you would be interested in obtaining a valuation and an offer from Helios please send us your details using the form below.
                </p>
              </div>
            </section>

            <section className={styles.formContainer}>

              <form className="padding-md" name="Lloyd’s Vehicle Valuation Enquiry" action="/sell-your-lloyds-vehicle/confirmation" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="Lloyd’s Vehicle Valuation Enquiry" />
                <div className={styles.field}>
                  <label>Full name</label>
                  <input type="text" name="full-name" required />
                </div>
                <div className={styles.field}>
                  <label>Email address</label>
                  <input type="email" name="email-address" required />
                </div>
                <div className={styles.field}>
                  <label>Phone number</label>
                  <input type="tel" name="phone-number" required />
                </div>
                <div className={styles.field}>
                  <label>Name of your Lloyd’s vehicle</label>
                  <input type="text" name="lloyds-vehicle-name" required />
                </div>
                <div className={styles.field}>
                  <label>Name of Members’ Agent</label>
                  <input type="text" name="members-agent-name" required />
                </div>
                <button className={`mb-10 ${styles.button}`} type="submit">Submit</button>
              </form>

            </section>

            <section className={`padding-md ${styles.smallPrint}`}>
              <p>This communication is directed only at persons in the United Kingdom and is not available to persons: (i) in the United States of America or its territories or possessions or to any US Person as defined in Regulation S under the Securities Act (including US resident corporations or other entities organised under the laws of the United States or any state thereof or non-United States branches or agencies of such corporations or entities), except as permitted under the Securities Act or to any US Person to whom this communication may be lawfully made in reliance on an exemption from registration that is available under the Securities Act (and by persons authorised to do so); or (ii) in Australia, New Zealand, Japan, South Africa, the member states of the EEA, or Canada, or any other jurisdiction which prohibits the same, except in compliance with applicable securities laws.</p>

              <p>In the United Kingdom, this communication and any further communications from, or other actions taken by, Helios (or its representatives) in connection with the same are exempt from the general prohibition set out in s.19 of the Financial Services and Markets Act 2000 (FSMA) and the financial promotion restriction set out in s.21 of FMSA, as the communication relates solely to the potential sale of a body corporate (pursuant to article 70 of the Financial Services and Markets Act 2000 (Regulated Activities) Order 2001 (SI 2001/544) and article 62 of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005 (SI 2005/1529), respectively). Accordingly, please only enter your details above if you control (or represent the controller(s) of) the relevant Lloyd’s vehicle.</p>

              <p>Neither this communication, nor Helios obtaining a valuation of the relevant Lloyd’s vehicle, shall constitute: (i) an offer by, or a representation or warranty on the part of, Helios (or its representatives) to enter into an agreement for the purchase of the relevant Lloyd’s vehicle or any other agreement; or (ii) an obligation on Helios to enter into discussions or negotiations in connection with the purchase of the relevant Lloyd’s vehicle.</p>
            </section>

          </div>

        </ColumnLayout>
      </Layout>
    )
  }
}

export default SellYourLloydsVehicle