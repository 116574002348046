import React from "react"
import axios from "axios"
import { formatDateTime, formatDecimal } from "helpers"

class SharePriceTicker extends React.Component {
  state = {
    loaded: false,
    error: false,
    shareTickerData: null,
  }

  componentDidMount() {
    this.fetchShareTickerData()
  }

  fetchShareTickerData = () => {
    axios
      .get(
        "https://tools.euroland.com/tools/pricefeed/?companycode=uk-huw&format=json"
      )
      .then(response => {
        this.setState({
          loaded: true,
          shareTickerData: response.data["Helios Underwriting"],
        })
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  formatChange(value) {
    const numericValue = parseFloat(value)
    const formattedValue = parseFloat(value).toFixed(2)
    return numericValue > 0
      ? `+ ${formattedValue}`
      : formattedValue.replace("-", "- ")
  }

  calculateMidPoint(pointA, pointB) {
    const pointAFloat = parseFloat(pointA)
    const pointBFloat = parseFloat(pointB)
    const midPoint = (pointAFloat + pointBFloat) / 2
    return midPoint
  }

  displayMidPoint(pointA, pointB) {
    const midPoint = this.calculateMidPoint(pointA, pointB)
    return formatDecimal(midPoint)
  }

  render() {
    return (
      this.state.loaded && (
        <div className="c-ticker">
          <div className="c-ticker__block">
            <h2 className="c-ticker__title">Share price</h2>
            <div className="c-ticker__value">
              {this.displayMidPoint(
                this.state.shareTickerData["Bid"],
                this.state.shareTickerData["Ask"]
              )}
            </div>
            <div className="c-ticker__unit">
              {this.state.shareTickerData["Currency"]}
            </div>
          </div>
          <div className="c-ticker__block">
            <div className="c-ticker__title">Change</div>
            <div className="c-ticker__change">
              {this.formatChange(this.state.shareTickerData["Change"])}
            </div>
            <div className="c-ticker__percentage">
              {this.formatChange(this.state.shareTickerData["ChangePercent"])}%
            </div>
          </div>

          <div className="c-ticker__footer">
            <span className="u-bold">Last updated&ensp;</span>
            <span className="c-ticker__date">
              {formatDateTime(this.state.shareTickerData["Date"])}
            </span>
          </div>
        </div>
      )
    )
  }
}

export default SharePriceTicker
