import React from "react"
import Divider from "components/divider"

export default () => (
  <section className="padding-md">
    <Divider color="terracotta" />
    <h2 className="mt-20 h2">Growth in retained capacity</h2>
    <h3 className="o-chart__title">£m</h3>
    <img
      alt="Chart showing growth in retained capacity"
      src="/assets/helios-capacity-chart-0125-v2.png"
      width="100%"
    />
  </section>
)
