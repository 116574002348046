import ChartJS from "chart.js"

export const chartDeferredOptions = {
  plugins: {
    deferred: {
      enabled: true,
      yOffset: "50%", // defer until 50% of the canvas height are inside the viewport
      delay: 300, // delay of 500 ms after the canvas is considered inside the viewport
    },
  },
}

export const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  rotation: 9,
  legend: {
    position: "bottom",
    labels: {
      fontSize: 14,
      fontFamily: "Helvetica Neue",
      fontColor: "#525252",
    },
  },
  hover: {
    mode: null,
    animationDuration: 0,
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    duration: 500,
    easing: "easeOutQuart",
    onComplete: function() {
      const chart = this.chart
      const ctx = chart.ctx
      const datasets = chart.data.datasets

      const fontSize = chart.options.legend.labels.fontSize || 12
      const fontFamily =
        chart.options.legend.labels.fontFamily || "Helvetica Neue"
      ctx.font = ChartJS.helpers.fontString(17, "bold", fontFamily)
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"

      datasets.forEach(dataset => {
        const meta = chart.getDatasetMeta(0)
        const total = meta.total

        meta.data.forEach((dataPoint, i) => {
          const {
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            x: centerX,
            y: centerY,
          } = dataPoint._model

          const midRadius = innerRadius + (outerRadius - innerRadius) / 2
          const midAngle = startAngle + (endAngle - startAngle) / 2

          const percent = (dataset.data[i] / total) * 100
          const displayPercent = `${Math.round(percent)}%`

          const radiusOffset = percent < 2 ? outerRadius + 20 : midRadius
          const x = radiusOffset * Math.cos(midAngle)
          const y = radiusOffset * Math.sin(midAngle)

          switch (Math.round(percent)) {
            case 9:
            case 1:
            case 5:
              ctx.fillStyle = "#000"
              break
            default:
              ctx.fillStyle = "#fff"
          }
          ctx.fillText(displayPercent, centerX + x, centerY + y)
        })
      })
    },
  },
}
